import React, { useEffect, useState } from 'react';
import { array, bool, node, string } from 'prop-types';

import css from './VideoCarousel.module.css';
import Button from './../Button/Button';
import Carousel from 'react-multi-carousel';

import Card from './Card';
const VideoCarousel = props => {
  const { children, className, rootClassName, spinnerClassName, videosList, ...rest } = props;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 2,
    },
  };

  return (
    <div className={css.mainWrapper}>
      <h2 className={css.heading}>Influencer's experience with SweetTooth</h2>
      <Carousel arrows responsive={responsive} className={css.carousel}>
        {Array.isArray(videosList) &&
          videosList.map((item, index) => <Card key={index} mediaItem={item} />)}
      </Carousel>
    </div>
  );
};

VideoCarousel.defaultProps = {
  rootClassName: null,
  className: null,
  spinnerClassName: null,
  checkmarkClassName: null,
  videosList: [],
  children: null,
};

VideoCarousel.propTypes = {
  rootClassName: string,
  className: string,
  spinnerClassName: string,
  checkmarkClassName: string,
  videosList: array,

  children: node,
};

export default VideoCarousel;
