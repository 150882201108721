import React, { useState, useEffect, useRef } from 'react';
import cakes from './assets/cakes.png';
import cookies from './assets/cookies.png';

const MobileCategories = () => {
  const categories = [
    {
      id: 1,
      mainTitle: 'Custom',
      subTitle: 'Cakes',
      image: cakes,
      link: '/s?keywords=custom%20cakes',
    },
    {
      id: 2,
      mainTitle: 'Custom',
      subTitle: 'Cookies',
      image: cookies,
      link: '/s?keywords=custom%20cookies',
    },
  ];

  const [isMobile, setIsMobile] = useState(false);
  const [contentOverflows, setContentOverflows] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);

      // Check if content overflows the carousel
      if (carouselRef.current) {
        const containerWidth = carouselRef.current.offsetWidth;
        const contentWidth = carouselRef.current.scrollWidth;
        setContentOverflows(contentWidth > containerWidth);
      }
    };

    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={styles.container}>
      {/* <h2>Find the perfect indulgence for every craving</h2> */}
      <div
        style={{
          ...styles.carouselContainer,
          maxWidth: isMobile ? '100%' : '1000px', // Full width for mobile, fixed width for desktop
          margin: isMobile ? '0' : '0 auto', // Center align on desktop
        }}
      >
        <div
          style={{
            ...styles.carouselWrapper,
            justifyContent: contentOverflows ? 'center' : 'center', // Adjust based on overflow
          }}
          ref={carouselRef}
        >
          <div style={styles.carouselTrack}>
            {categories.map(category => (
              <a
                key={category.id}
                href={category.link}
                style={{
                  ...styles.card,
                  flex: `0 0 ${100 / (isMobile ? 6 : 8)}%`, // Adjust based on items per page
                }}
              >
                <div style={styles.imageContainer}>
                  <img src={category.image} alt={category.title} style={styles.image} />
                </div>
                <p style={styles.title}>{category.mainTitle}</p>
                <p style={styles.title}>{category.subTitle}</p>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    zIndex: '2',
  },
  carouselContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
  },
  carouselWrapper: {
    display: 'flex',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    padding: '10px 0',
    scrollbarWidth: 'none', // Hide scrollbar for Firefox
    WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
  },
  carouselTrack: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '20px',
  },
  card: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageContainer: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // border: '2px solid #ddd',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    background: 'rgb(254,253,249)',
  },
  title: {
    margin: '0px',
    fontSize: '14px',
    fontWeight: '500',
    paddingBottom: '0px',
  },
};

export default MobileCategories;
