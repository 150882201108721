import React, { useEffect, useRef, useState } from 'react';
import logo from './assets/howitworks.png';
import css from './FrontPageBanner.module.css';
import TopbarSearchForm from '../../components/Topbar/TopbarSearchForm/TopbarSearchForm';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';

import ScrollingPartners from '../../containers/FrontPageBanner/ScrollingPartners';
import VideoCarousel from '../../components/VideoCarousel/VideoCarousel';
import MobileCategories from './MobileCategories';
import first_vid from '../../assets/Videos/first.MOV';
import second_vid from '../../assets/Videos/second.MOV';
import first_thumb from '../../assets/Videos/first.png';
import second_thumb from '../../assets/Videos/second.png';
import bg_img4 from './assets/bg_img1.jpg';
import bg_img2 from './assets/bg_img2.jpg';
import bg_img3 from './assets/bg_img3.jpg';
import bg_img1 from './assets/bg_img4.jpg';

import EventListingsPageContainer from '../EventListingsPage/EventListingsPage';

const FrontPageBanner = () => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const searchBarRef = useRef(null);
  const [isPastSearchBar, setIsPastSearchBar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (searchBarRef.current) {
        const searchBarPosition = searchBarRef.current.getBoundingClientRect().top;
        const isPast = searchBarPosition < 0;

        if (isPast && !isPastSearchBar) {
          console.log('User has scrolled past the search bar.');
          setIsPastSearchBar(true);
        } else if (!isPast && isPastSearchBar) {
          setIsPastSearchBar(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isPastSearchBar]);

  const handleSubmit = values => {
    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = topbarSearchParams();
    const url = createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams);
    // Redirect to the generated URL
    window.location.href = url;
  };

  const search = (
    <div className={css.searchBarContainer} ref={searchBarRef}>
      <div className={css.searchBarWrapper}>
        <TopbarSearchForm
          className={css.searchLink}
          desktopInputRoot={css.topbarSearchWithLeftPadding}
          onSubmit={handleSubmit}
          appConfig={config}
        />
      </div>
    </div>
  );

  const videosList = [
    { video: first_vid, thumb: first_thumb },
    { video: second_vid, thumb: second_thumb },
  ];

  return (
    <>
      {isPastSearchBar && (
        <div className={css.stickySearchBar}>
          <TopbarSearchForm
            className={css.searchLink1}
            desktopInputRoot={css.topbarSearchWithLeftPadding}
            onSubmit={handleSubmit}
            appConfig={config}
          />
        </div>
      )}
      <div className={css.container}>
        <div className={css.contentContainer}>
          <div className={css.backgroundContainer}>
            <div className={css.backgroundImage}></div>
            <div className={css.textImageContainer}>
              <div className={css.overlayContent}>
                <div className={css.imageContainerLeft}>
                  <img src={bg_img1} alt="Image 1" className={`${css.image} ${css.image1}`} />
                  <img src={bg_img2} alt="Image 2" className={`${css.image} ${css.image2}`} />
                </div>

                <div className={css.textContainer}>
                  <h1>Get your Perfect</h1>
                  <h1>Custom Dessert</h1>
                  <p>
                    Discover and order custom desserts for any occasion from expert bakers and
                    bakeries
                  </p>
                  <MobileCategories />
                  {search}

                  <div className={css.vShapeContainer}>
                    <img src={bg_img1} alt="Image 1" className={css.imageTop} />
                    <img src={bg_img2} alt="Image 2" className={css.imageTop} />
                    <img src={bg_img4} alt="Image 3" className={css.imageBottom} />
                  </div>
                </div>

                <div className={css.imageContainerRight}>
                  <img src={bg_img3} alt="Image 3" className={`${css.image} ${css.image3}`} />
                  <img src={bg_img4} alt="Image 4" className={`${css.image} ${css.image4}`} />
                </div>
              </div>
            </div>
          </div>
          <ScrollingPartners />
          <EventListingsPageContainer keyword="Baby shower" config={config} />
          <EventListingsPageContainer keyword="Birthday" config={config} />
          <EventListingsPageContainer keyword="Bridal shower" config={config} />
          <EventListingsPageContainer keyword="Wedding" config={config} />
          <VideoCarousel videosList={videosList} />
        </div>
      </div>
    </>
  );
};

export default FrontPageBanner;
