import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { IconSpinner } from '../../components';
import SimilarListingsCarousel from '../ListingPage/SimilarListingsCarousel';
import css from './EventListingsPage.module.css';
import { loadData } from './EventListingsPage.duck';

class EventListingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listings: {}, // Use an object to store listings for different keywords
      loading: {}, // Use an object to track loading states for each keyword
      error: {}, // Use an object to track errors for each keyword
    };
  }

  componentDidMount() {
    const { keyword, config } = this.props;
    if (keyword) {
      this.fetchEventListings(keyword, config);
    }
  }

  componentDidUpdate(prevProps) {
    const { keyword, config } = this.props;
    if (keyword !== prevProps.keyword) {
      this.fetchEventListings(keyword, config);
    }
  }

  fetchEventListings = async (keyword, config) => {
    this.setState(prevState => ({
      loading: { ...prevState.loading, [keyword]: true },
      error: { ...prevState.error, [keyword]: null },
    }));

    try {
      // Call the backend API
      const data = await loadData(keyword, config);

      if (!data) {
        throw new Error(`No listings for ${keyword}`);
      }

      this.setState(prevState => ({
        listings: { ...prevState.listings, [keyword]: data || [] },
        loading: { ...prevState.loading, [keyword]: false },
      }));
    } catch (error) {
      console.error(error);
      this.setState(prevState => ({
        error: { ...prevState.error, [keyword]: error.message },
        loading: { ...prevState.loading, [keyword]: false },
      }));
    }
  };

  render() {
    const { keyword, config } = this.props;
    const { listings, loading, error } = this.state;

    const loadingSpinner = (
      <div className={css.loadingContainer}>
        <IconSpinner />
      </div>
    );

    const keywordListings = listings[keyword] || [];
    const keywordLoading = loading[keyword];
    const keywordError = error[keyword];

    return (
      <div className={css.listings}>
        {keywordLoading ? (
          loadingSpinner
        ) : keywordError ? (
          <div className={css.errorMessage}>
            <p>Failed to load event listings. Please try again later.</p>
          </div>
        ) : keywordListings.length === 0 ? (
          <div className={css.noListingsMessage}>
            <p>No event listings found for the keyword "{keyword}".</p>
          </div>
        ) : (
          <SimilarListingsCarousel
            similarListings={keywordListings} // Pass fetched event listings here
            config={config} // Pass configuration for ListingCard
            title={`Shop for ${keyword}s`}
            fromEvent={true}
            keyword={keyword}
          />
        )}
      </div>
    );
  }
}

EventListingsPage.propTypes = {
  keyword: PropTypes.string.isRequired, // Keyword for fetching listings
  config: PropTypes.object.isRequired, // Configuration for fetching data
};

export default EventListingsPage;
