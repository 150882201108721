import React, { useRef, useEffect } from 'react';
import css from './ScrollingPartners.module.css';

import partner1 from './assets/nike-seeklogo.png';
import partner2 from './assets/loreoal-paris-vector-logo.svg';
import partner3 from './assets/los-angeles-fc-logo-transparent.png';
import partner4 from './assets/Abbott_Laboratories_logo.png';
import partner5 from './assets/Coty_Inc_logo_2016.png';
import partner6 from './assets/University-of-Utah-Logo.png';
import partner7 from './assets/YSL.png';
import partner8 from './assets/quadrangle_development_logo.png';
import partner9 from './assets/ThomasAEd.png';
import partner10 from './assets/Bun.png';
import partner11 from './assets/cooper_vision.png';

const ScrollingPartners = () => {
  const sponsors = [
    { id: 1, image: partner1 },
    { id: 2, image: partner2 },
    { id: 3, image: partner3 },
    { id: 4, image: partner4 },
    { id: 5, image: partner5 },
    { id: 6, image: partner6 },
    { id: 7, image: partner7 },
    { id: 8, image: partner8 },
    { id: 9, image: partner9 },
    { id: 10, image: partner10 },
    { id: 11, image: partner11 },
  ];
  const trackRef = useRef(null);

  useEffect(() => {
    const track = trackRef.current;

    // Clone the images dynamically to create an infinite loop
    const firstSet = track.innerHTML;
    track.innerHTML += firstSet;

    let start = 0;

    const scroll = () => {
      start -= 0.75; // Adjust speed of scrolling
      if (start <= -track.scrollWidth / 2) {
        start = 0; // Reset the scroll position seamlessly
      }
      track.style.transform = `translateX(${start}px)`;
      requestAnimationFrame(scroll);
    };

    scroll();
  }, []);

  return (
    <>
      <div className={css.mainWrapper}>
        <h2 className={css.heading}>Trusted By</h2>
        <div className={css.carouselWrapper}>
          <div className={css.carouselTrack} ref={trackRef}>
            {sponsors.map((sponsor, index) => (
              <div key={index} className={css.sponsorItem}>
                <img
                  src={sponsor.image}
                  alt={`Sponsor ${index + 1}`}
                  className={css.sponsorImage}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScrollingPartners;
