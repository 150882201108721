import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import sdk from '../../util/sdkUtil';

// ================ Thunks ================ //

const getImageVariantInfo = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [
    'variants.scaled-small',
    'variants.scaled-medium',
    `variants.${variantPrefix}`,
    `variants.${variantPrefix}-2x`,
  ];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};

export const loadData = async (keyword, config) => {
  // dispatch(fetchEventListingsRequest(keyword));

  try {
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);

    const response = await sdk.listings
      .query({
        keywords: keyword,
        include: ['images', 'author'],
        meta_isHiddenOnMainPage: false,
        pub_shippingEnabled: true,
        perPage: 10,
        'fields.image': imageVariantInfo.fieldsImage,
        ...imageVariantInfo.imageVariants,
      })
      .then(response => {
        const { data, included } = response.data;

        // Map the image IDs to actual image objects
        const images = included.filter(item => item.type === 'image');
        const authors = included.filter(item => item.type === 'user');
        const listingsWithImages = data.map(listing => {
          // Map the image ids to actual image objects
          const listingImages = listing.relationships.images.data.map(imgRel =>
            images.find(img => img.id.uuid === imgRel.id.uuid)
          );

          const listingAuthor = authors.find(
            author => author.id.uuid === listing.relationships.author.data.id.uuid
          );

          return { ...listing, images: listingImages, author: listingAuthor };
        });
        // dispatch(fetchEventListingsSuccess(listingsWithImages));
        return listingsWithImages;
      });

    if (response.length === 0) {
      throw new Error('No listings found.');
    }
    return response;
  } catch (error) {
    console.error(error);
    // dispatch(fetchEventListingsFailure(storableError(error)));
  }
};
